<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="50%" @close="close">
      <el-button type="primary" @click="openChangeCoupon">添加优惠券</el-button>
      <div class="coupons-list" style="margin-top: 10px;">
          <el-table ref="dataTableRef" v-if="isShow" :data="dataList" highlight-current-row height="400px">
            <el-table-column label="名称" prop="name" align="center" />
            <el-table-column label="价值" prop="benefitsAmount" align="center">
              <template #default="scope">
                <el-input-number :controls="false" v-model="scope.row.benefitsAmount" :min="1"
                    style="width: 100%;"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="剩余｜总量" prop="total" align="center">
              <template #default="scope">
                <span>{{ scope.row.surplus }}</span>
                <span class="line">｜</span>
                <span>{{ scope.row.total }}</span>
                <p v-if="scope.row.surplus == 0" style="color: red;">（剩余量不足）</p>
              </template>
            </el-table-column>
            <el-table-column label="发放数量" prop="total" align="center">
              <template #default="scope">
                <el-input-number :controls="false" v-model="scope.row.count" :min="1" :precision="0"
                    style="width: 100%;"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button @click="handleRemove(scope.$index)" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick()" type="primary">保存</el-button>
      </template>
    </el-dialog>
    <ChangeCoupon ref="ChangeCoupon" @saveForm="saveCouponList"></ChangeCoupon>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, nextTick, handleError } from 'vue'
import { ElMessage } from 'element-plus'
import ChangeCoupon from './changeCoupon.vue'
export default defineComponent({
  props: {
  },
  components: {
    ChangeCoupon
  },
  setup(props, { emit }) {
    const isShow = ref(false)
    const title = ref('编辑权益')
    const formType = ref(null)
    const dataList = ref([])
    const dataType = ref(null)
    const couponListSelecd = ref([])
    const checkedCouponList = ref([])
    const ChangeCoupon = ref(null)
    const handleSelectionChange = (value) =>{
      couponListSelecd.value=value.map(item=>{
        return item.id
      })
      checkedCouponList.value = value
    }
    const dataTableRef = ref()
    const open = (data) =>{
      isShow.value = true
      dataType.value = data.dataType
      dataList.value = JSON.parse(JSON.stringify(data.dataList))
    }
    const close = () =>{
      couponListSelecd.value = []
      isShow.value = false
    }
    const openChangeCoupon = ()=>{
      ChangeCoupon.value.open(dataList.value)
    }
    const saveCouponList = (data) => {
      data.map(i=>{
        i.couponId=i.id
      })
      dataList.value = [...dataList.value,...data]
    }
    const handleRemove = (i) =>{
      dataList.value.splice(i,1)
    }

    const handleSaveClick = async () => {
      // if (dataList.value.length == 0) {
      //   ElMessage({
      //     message: '请选择优惠券！',
      //     type: 'warning',
      //   })
      //   return
      // } 
      // else if (dataList.value.length > 15) {
      //   ElMessage({
      //     message: '优惠券最多可选15张！',
      //     type: 'warning',
      //   })
      //   return
      // }
      // let couponIds = dataList.value.map(item => {
      //   return {
      //     couponId: item.id,
      //     count: item.count,
      //     benefitsAmount: item.benefitsAmount,
      //   }
      // })
      let isHasNoCount = dataList.value.some(item => isNaN(item.count)||isNaN(item.benefitsAmount))
      if (isHasNoCount) {
        ElMessage({
          message: '请检查优惠券发放数量是否填写完整！',
          type: 'warning',
        })
        return
      }
      emit('saveForm', {dataList:dataList.value,dataType:dataType.value})
      close()
    }

    return {
      handleSaveClick,
      isShow,
      title,
      open,
      close,
      formType,
      dataList,
      handleSelectionChange,
      couponListSelecd,
      dataTableRef,
      ChangeCoupon,
      openChangeCoupon,
      saveCouponList,
      handleRemove
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
