<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="30%" @close="close">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-suffix="："
      >
      <el-form-item label="权益名称" prop="name">
        <el-input style="width: 80%" v-model="ruleForm.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item v-if="formType!=1" label="权益描述" prop="description">
        <el-input style="width: 80%" v-model="ruleForm.description" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="权益图标" prop="icon">
        <upload-img v-model="ruleForm.url" :limit="1"></upload-img>
        <div class="tips">支持jpg、png格式，最多上传1张</div>
      </el-form-item>
    </el-form>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
 import UploadImg from '@/components/page-upload/UploadFileList.vue'

export default defineComponent({
  props: {
  },
  components: {
    UploadImg,
  },
  setup(props, { emit }) {
    const ruleForm = reactive({
      pic:'',
      name:''
    })
    const rules = {
      name: [{ required: true, message: '请输入', trigger: 'blur' }],
      description: [{ required: true, message: '请输入', trigger: 'blur' }]
    }
    const ruleFormRef = ref()
    const isShow = ref(false)
    const title = ref('新增权益')
    const editData = ref(null)
    const formType = ref(null)
    const open = (data) =>{
      if (data.name) {
        title.value = '编辑权益'
        ruleForm.name = data.name
        ruleForm.url = data.url
        editData.value = data
        ruleForm.description = data.type>1?data.description:undefined
      } else {
        title.value = '新增权益'
      }
      formType.value = data.type
      isShow.value = true
    }
    const close = () =>{
      isShow.value = false
      ruleFormRef.value.resetFields()
      ruleForm.url=''
      ruleForm.name=''
      ruleForm.description=''
      editData.value = null
      formType.value = null
    }

    const handleSaveClick = async(formEl) => {
      if (!formEl) return
        await formEl.validate((valid, fields) => {
          if (valid) {
            if (editData.value) {
              let params = {
                url:ruleForm.url,
                name:ruleForm.name,
                index:editData.value.index,
                type:formType.value,
                enabled:editData.value.enabled,
                description:formType.value>1?ruleForm.description:undefined
              }
              emit('saveForm',params)
            }else{
              emit('saveForm',{type:formType.value,...ruleForm})
            }
            close()
          }
        })
    }

    return {
      handleSaveClick,
      isShow,
      ruleForm,
      title,
      ruleFormRef,
      open,
      rules,
      close,
      formType
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
