<!-- 
 单文件上传
-->
<template>
    <el-upload
      :file-list="fileList"
      accept=".jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PBG, .GIF, .BMP"
      :limit="props.limit"
      :drag="props.drag"
      :show-file-list="showFileList"
      :headers="headers"
      :action="UPLOAD_BASE_URL+'/manager/upload'"
      list-type="picture-card" name="file"
      :on-remove="handleRemove"
      :on-success="handleUploadSuccess"
      :class="{ limitActive: fileList.length && props.limit == 1 }"
    >
      <slot>
        <el-icon>
          <plus />
        </el-icon>
      </slot>
      <template v-if="showTips" #tip>
        <div class="el-upload__tip">
          {{ props.tips }}
        </div>
      </template>
    </el-upload>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue'
  import { UPLOAD_BASE_URL } from '@/common/constant'
  const emit = defineEmits(["update:modelValue"]);
  
  const props = defineProps({
    /**
     * 文件路径集合
     */
    modelValue: {
      type: String,
      default: () => [],
    },
    /**
     * 文件上传数量限制
     */
    limit: {
      type: Number,
      default: 10,
    },
    // 上传格式
    accept: {
      type: String,
      default: null,
    },
    // 上传提示
    tips: {
      type: String,
      default: ''
    },
    // 是否是拖拽上传
    drag: {
      type: Boolean,
      default: false
    },
    // 是否显示上传列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 是否显示提示
    showTips: {
      type: Boolean,
      default: true
    },
    // 上传按钮名称
    btnText: {
      type: String,
      default: '上传文件'
    }
  });
  const fileList = ref([]);
  
  watch(() => props.modelValue, (newVal) => {
    // console.log("newVal",newVal);
    let array = []
    if (!newVal || !newVal.length) return;
    let filename = newVal.split('/')[newVal.split('/').length-1]
    array = [{ 'url': newVal, 'name': filename }]
    fileList.value = array.map(i => {
      return {
        url: i?.url,
        name: i?.name
      }
    })
  },
    {
      deep: true,
      immediate: true,
    }
  );
  // 上传成功后触发
  const handleUploadSuccess = (response) => {
    console.log("newVal",response);
    let url = decodeURIComponent(response.data.fileName); // 文件解析
    fileList.value = ({ url })
    emit(
      "update:modelValue", url
    );
  }
  
  
  /**
   * 删除
   */
  function handleRemove(removeFile, file) {
    fileList.value = []
    emit(
      "update:modelValue", ''
    );
  }
  
  </script>
<style scoped lang="less">
    &:deep(.el-upload.is-drag) {
        width: 600px;
    }
    .limitActive {
        &:deep(.el-upload--picture-card) {
         display: none !important;
        }
    }
</style>
  