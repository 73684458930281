<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="50%" @close="close">
      <el-tabs v-model="activeStatus" type="card" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in serveCategoryList" :key="index" :label="item.kindName" :name="item.kindId">
        </el-tab-pane>
        <div class="coupons-list">
          <el-table ref="dataTableRef" v-if="isShow" :data="dataList" highlight-current-row @selection-change="handleSelectionChange"
            :row-key="getkey" height="400px">
            <el-table-column align="center" type="selection" :reserve-selection="true" :selectable="selectable"/>
            <el-table-column label="类型" prop="serveKind" align="center"/>
            <el-table-column label="名称" prop="serveName" align="center"/>
            <el-table-column label="售价" prop="price" align="center">
              <template #default="scope">
                <p>{{ '¥' + scope.row.price }}</p>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="margin-top: 10px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="queryInfo.page" :page-size="queryInfo.pageSize" :page-sizes="[10, 20, 30]"
            layout="total, sizes, prev, pager, next, jumper" :total="dataCount"></el-pagination>
        </div>
      </el-tabs>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick()" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted, nextTick } from 'vue'
import UploadImg from '@/components/page-upload/UploadFileList.vue'
import { getServeList,getServeCategoryList } from '@/service/main/serve'
import { ElMessage } from 'element-plus'
import { COMPANY_ID } from '@/common/constant'
export default defineComponent({
  props: {
  },
  components: {
    UploadImg,
  },
  setup(props, { emit }) {
    const isShow = ref(false)
    const title = ref('添加服务')
    const formType = ref(null)
    const tabStatusList = reactive([
    {
      value: 0,
      label: '全部'
    },{
      value: 1,
      label: '现金券'
    },{
      value: 2,
      label: '折扣券'
    },{
      value: 3,
      label: '兑换券'
    }])
    const dataList = ref([])
    const activeStatus = ref(null)
    const serveCategoryList = ref([])
    const dataCount = ref(0)
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const selecdList = ref([])
    const selectable = (row) => {
     return !selecdList.value.some(item => item.serveId === row.serveId)
    }
    const getkey = (row) =>{
      return row.id
    }
    const handleSizeChange = size => {
      queryInfo.pagesize = size
      getCouponList()
    }
    const handleCurrentChange = page => {
      queryInfo.page = page
      getCouponList()
    }
    const handleClick = async () => {
      getCouponList()
    }
    const getCouponList = async (data) => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        kindid:activeStatus.value,
        servestatus:1
      }
      const res = await getServeList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }
    
    const checkedCouponList = ref([])
    const handleSelectionChange = (value) =>{
      checkedCouponList.value = value
    }
    const getTypeList = async() =>{
      let res = await getServeCategoryList(COMPANY_ID)
      serveCategoryList.value = res.data.list
    }
    getTypeList()
    const dataTableRef = ref()
    const open = (data) =>{
      isShow.value = true
      activeStatus.value = serveCategoryList.value[0].kindId
      selecdList.value = data
      getCouponList()
    }
    const close = () =>{
      checkedCouponList.value = []
      isShow.value = false
    }

    const handleSaveClick = async () => {
      if (checkedCouponList.value.length == 0) {
        ElMessage({
          message: '请选择优惠券！',
          type: 'warning',
        })
        return
      } 
      // else if (checkedCouponList.value.length > 15) {
      //   ElMessage({
      //     message: '优惠券最多可选15张！',
      //     type: 'warning',
      //   })
      //   return
      // }
      emit('saveForm',checkedCouponList.value)
      close()
    }

    return {
      handleSaveClick,
      isShow,
      title,
      open,
      close,
      formType,
      handleSaveClick,
      tabStatusList,
      dataList,
      dataCount,
      queryInfo,
      handleCurrentChange,
      getCouponList,
      handleClick,
      activeStatus,
      handleSelectionChange,
      getkey,
      handleSizeChange,
      dataTableRef,
      selectable,
      serveCategoryList,
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
